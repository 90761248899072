import { getRequest, patchRequest, postRequest } from "./global";

export const getProjectTimeline = (id) => {
  return getRequest(`project/timeline?id=${id}`);
};

export const getProjectData = (id) => {
  return getRequest(`project/info?projectId=${id}`);
};

/**
 * Creates a new project by formatting the input data into FormData and making a POST request.
 *
 * @param {Object} data - The data object containing project information.
 * @return {Promise} A Promise that resolves with the response data of the POST request.
 */
export const createProject = (data) => {
  const formData = transformToMultiPartFormData(data);

  return postRequest(`project/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateProject = (data) => {
  const formData = transformToMultiPartFormData(data);

  return patchRequest(`project/update`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

function transformToMultiPartFormData(data) {
  const formData = new FormData();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
}
