import React, { useState } from "react";
import Button from "../../../components/Button";

const SCREEN_NUMBERS = { min: 1, max: 1 };

const Navigation = ({
  currentForm,
  setCurrentForm,
  onCancel,
  isEditScreen,
  onSaveChanges,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleSaveClick = () => {
    setClicked(true);
    onSaveChanges();

    setTimeout(() => {
      setClicked(false);
    }, 3000);
  };

  if (isEditScreen) {
    return (
      <div className="flex gap-4 mt-10">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={clicked}
          onClick={handleSaveClick}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Save Changes
        </Button>
      </div>
    );
  }

  return (
    <div className="flex gap-4 mt-10">
      {currentForm === SCREEN_NUMBERS.min && (
        <Button type="secondary" onClick={onCancel}>
          Back to Projects
        </Button>
      )}
      {/* {currentForm !== SCREEN_NUMBERS.min && (
        <Button
          type="secondary"
          onClick={() => setCurrentForm(currentForm - 1)}
        >
          Previous
        </Button>
      )} */}
      {/* {currentForm !== SCREEN_NUMBERS.max && (
        <Button onClick={() => setCurrentForm(currentForm + 1)}>Next</Button>
      )} */}
      {currentForm === SCREEN_NUMBERS.max && (
        <Button disabled={clicked} type="submit" onClick={handleSaveClick}>
          Create Project
        </Button>
      )}
    </div>
  );
};

export default Navigation;
