import React, { useState, useEffect } from "react";

const ImageUploader = ({
  initialImages = [],
  onImageUpload = () => {},
  ...props
}) => {
  const [images, setImages] = useState(initialImages);
  const [selectedImages, setSelectedImages] = useState([]);

  // useEffect(() => {
  //     setImages(initialImages);
  // }, [initialImages]);

  const handleImageSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);

    setSelectedImages(selectedFiles);
  };

  const handleImageUpload = () => {
    if (selectedImages.length === 0) {
      alert("Please select an image to upload.");
      return;
    }

    // Filter out duplicate images from selectedImages
    const newImages = selectedImages.filter(
      (selectedImage) =>
        !images.some((image) => image.name === selectedImage.name)
    );

    const updatedImages = [...images, ...newImages];
    setImages(updatedImages);
    onImageUpload(newImages);
    setSelectedImages([]);
  };

  const handleImageRemove = (imageName) => {
    const updatedImages = images.filter((image) => image.name !== imageName);
    setImages(updatedImages);
  };

  // TODO:  images handling for cases - multiple logo sizes and multiple - splash
  return (
    <div>
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor={props.id || "dropzone-file"}
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark-to-replace:hover:bg-bray-800 dark-to-replace:bg-gray-700 hover:bg-gray-100 dark-to-replace:border-gray-600 dark-to-replace:hover:border-gray-500 dark-to-replace:hover:bg-gray-600"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark-to-replace:text-gray-400">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500 dark-to-replace:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 10MB)
            </p>
          </div>
          <input id="dropzone-file" type="file" className="hidden" {...props} />
        </label>
      </div>
      {/* <button onClick={handleImageUpload}>Upload</button> */}
      <div className="image-preview">
        {images.map((image) => (
          <div key={image.name} className="image-container">
            <img src={image.url} alt={image.name} />
            <button onClick={() => handleImageRemove(image.name)}>X</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
