import React from 'react';
import 'hint.css/hint.min.css';

// using hint.css
const Tooltip = ({ children, tooltipText, className = '' }) => {
    return (
        <div
            className={`group inline-flex relative hint--no-arrow hint--rounded ${className || 'hint--bottom'}`}
            aria-label={tooltipText}
        >
            {children}
        </div>
    )
}

export default Tooltip