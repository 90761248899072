import React, { useContext, useEffect } from "react";
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip/index";
import { downloadFile } from "../../utils/download-helper";
import ModalContext from "../../contexts/modal.context";
import { AWAITABLE_COMPONENT_STATUS } from "../../hooks/use-awaitable-component";

const ProjectInfo = ({
  isNewProject = false,
  projectData = {},
  handleBuildStart,
  isBuildInProgress = false,
}) => {
  const {
    splashImageName,
    lastSuccessfulBuildId,
    logoImageName,
    platform,
    appName,
    githubProjectLink,
    websiteUrl,
    imagesLink,
    projectId,
    lastBuildLink,
    hasNewUpdates,
  } = projectData;

  const modalData = useContext(ModalContext);

  useEffect(() => {
    if (modalData.modalStatus === AWAITABLE_COMPONENT_STATUS.RESOLVED) {
      handleBuildStart();
      modalData.modalReset();
    }
  }, [modalData.modalStatus]);

  const handleOnClickStartBuild = async () => {
    modalData.openModal({
      title: "Ready to build? ",
      body: 'Ready to build your app? Clicking "Proceed" will start the build process using your current settings. Once started, the build cannot be interrupted.',
      acceptButtonText: "Proceed",
    });
  };

  return (
    <>
      {!isNewProject && hasNewUpdates && !isBuildInProgress && (
        <div
          className="flex items-center p-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark-to-replace:bg-gray-800 dark-to-replace:text-blue-400 dark-to-replace:border-blue-800"
          role="alert"
        >
          <svg
            className="flex-shrink-0 inline w-4 h-4 mr-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">Hey there!</span> Seems like new
            changes were added, create new build of app by hitting
            <Button
              disabled={!hasNewUpdates || isBuildInProgress}
              className="max-w-fit text-xs !px-2 py-1 rounded-md mx-2 !inline-block"
              onClick={handleOnClickStartBuild}
            >
              Start Build
            </Button>{" "}
            button.
          </div>
        </div>
      )}
      <div className="p-4 md:p-8 w-full bg-white border border-gray-200 rounded-lg shadow dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700">
        <div id="fullWidthTabContent" className="mb-5">
          <div
            className="bg-white rounded-lg dark-to-replace:bg-gray-800"
            id="about"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <div className="flex flex-wrap justify-between items-start">
              <h2 className="flex items-center text-2xl font-extrabold tracking-tight text-gray-900 dark-to-replace:text-white">
                {projectData?.logoImageDownloadLink ? (
                  <img
                    className="w-10 h-10 rounded-full border border-gray-200 mr-4"
                    src={projectData?.logoImageDownloadLink}
                    alt="Logo"
                  />
                ) : (
                  <div className="relative inline-flex items-center justify-center w-10 h-10 mr-4 overflow-hidden bg-gray-100 rounded-full dark-to-replace:bg-gray-600">
                    <span className="font-medium text-gray-600 dark-to-replace:text-gray-300">
                      {appName?.[0]}
                    </span>
                  </div>
                )}
                <span>{appName}</span>

                <Tooltip
                  tooltipText="Edit Project"
                  className="ml-3 hint--bottom"
                >
                  <a
                    href={`/project/${projectId}/edit/`}
                    className="cursor-pointer text-blue-700 hover:bg-blue-100 hover:text-white focus:outline-none font-normal rounded-md text-sm p-1.5 text-center inline-flex items-center dark-to-replace:text-blue-500 dark-to-replace:hover:text-white dark-to-replace:hover:bg-blue-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </a>
                </Tooltip>
              </h2>
              <div className="flex gap-4 items-center float-right">
                {lastBuildLink && (
                  <Tooltip
                    tooltipText="Download latest build"
                    className="ml-4 hint--bottom"
                  >
                    <button
                      className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-md text-sm p-1.5 text-center inline-flex items-center dark-to-replace:border-blue-500 dark-to-replace:text-blue-500 dark-to-replace:hover:text-white dark-to-replace:focus:ring-blue-800 dark-to-replace:hover:bg-blue-500 disabled:text-gray-400 disabled:border-gray-300 disabled:pointer-events-none disabled:cursor-not-allowed"
                      onClick={() => {
                        downloadFile(lastBuildLink);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                )}

                {/* Todo: functionality to pause */}
                <Tooltip
                  className="hint--bottom w-max"
                  tooltipText={
                    !hasNewUpdates
                      ? "No new updates since last build"
                      : isBuildInProgress
                      ? "Currently build in progress"
                      : "Create a new build"
                  }
                >
                  <Button
                    disabled={!hasNewUpdates || isBuildInProgress}
                    className="max-w-fit disabled:text-gray-200 disabled:border-gray-300 disabled:pointer-events-none disabled:bg-gray-100"
                    onClick={handleOnClickStartBuild}
                  >
                    Start Build
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="ml-2 w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 pt-5 flex gap-4 text-sm">
          <div>
            <label className="text-gray-800 font-medium">Website</label>:{" "}
            {websiteUrl}
          </div>

          <div className="capitalize">
            <label className="text-gray-800 font-medium">Platforms</label>:{" "}
            {platform}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectInfo;
