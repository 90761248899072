import React, { useEffect, useState } from "react";
import { getRelativeTime } from "../../utils/date-helper";
import { getAllPayments } from "../../api/payment";
import Loader from "../../components/Loader";

function Account() {
  const [paymentData, setPaymentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await getAllPayments();
        setPaymentData(data);
      } catch (err) {
        // alert("Something went wrong, Please reload.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="pl-40 pr-20 flex flex-col gap-4 mt-4 text-gray-900">
      <div className="flex pb-4 items-center gap-2">
        <div className="w-9 h-9 rounded-full bg-orange-100 p-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#9a3412"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
        </div>
        <h1 className="text-2xl font-semibold">Account</h1>
      </div>
      <div className="px-4 py-4 mb-5 bg-white dark-to-replace:bg-gray-900 border-b shadow-sm sm:rounded-lg">
        <h2 className="text-xl font-medium mb-4">Account Info</h2>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <h5 className="font-medium text-gray-800">Name:</h5>
            <span>{userData.name}</span>
          </div>
          <div className="flex gap-4">
            <h5 className="font-medium text-gray-800">Email:</h5>{" "}
            <span>{userData.email}</span>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 mb-5 bg-white dark-to-replace:bg-gray-900 border-b shadow-sm sm:rounded-lg">
        <h2 className="text-xl font-medium mb-4">Purchase Details</h2>
        <div>
          <table className="w-full text-sm text-left text-gray-500 dark-to-replace:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-indigo-100 dark-to-replace:bg-gray-700 dark-to-replace:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Payment ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Pack name
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment on
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentData?.map((project) => (
                <tr
                  key={project.id}
                  className="bg-white border-b dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700 hover:bg-blue-50 dark-to-replace:hover:bg-gray-600"
                >
                  <td>
                    <div className="px-6 py-3">
                      <div>{project.id}</div>
                    </div>
                  </td>
                  <td>
                    <div className="px-6 py-3">
                      <div className="font-semibold">
                        {project.purchasedPlanName}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {getRelativeTime(project.paymentOn) || "None"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading ? (
            <div className="w-full h-40">
              <Loader size="md" />
            </div>
          ) : (
            !paymentData?.length && (
              <div className="w-full h-32">
                <h5 className="w-fit mx-auto mt-8">No data present</h5>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Account;
