import React, { useState } from 'react'
import Timeline from '../../components/Timeline'

// TODO: currenly no concept of user/groups. will add in the future
const ProjectBuild = ({ timelineData = [], handleItemClick, activeItemIndex }) => {

    return (
        <div className='w-4/12 build-container p-5 overflow-y-auto overflow-x-hidden h-screen'>
            <Timeline data={timelineData} activeItemIndex={activeItemIndex} handleItemClick={handleItemClick} />
        </div>)
}

export default ProjectBuild