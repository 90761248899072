import { TIMELINE_STATUS, TIMELINE_TYPE } from "./timeline.constants";
import { getRelativeTime } from "../../utils/date-helper";

export const transformTimelineData = (timelineData = []) => {
  return (timelineData || []).map((timelineBlock) => {
    if (timelineBlock?.changes) {
      // project update timeline-block
      return {
        ...timelineBlock,
        title: "Project info updated",
        type: TIMELINE_TYPE.INFO_UPDATE,
        status: TIMELINE_STATUS.SUCCESS,
        time: getRelativeTime(timelineBlock.createdAt),
      };
    } else if (timelineBlock.buildStatus) {
      // Build timeline-block
      let progress = 10;
      const steps = ["Build started"];

      if (timelineBlock?.setupComplete) {
        steps.push("Project files generated");
        steps.push("Android: Project build in progress");
        progress += 60;
      }

      if (timelineBlock?.buildStatus === TIMELINE_STATUS.SUCCESS) {
        steps.push("Project build completed successfully");
        progress = 100;
      }

      if (timelineBlock?.buildStatus === TIMELINE_STATUS.FAILED) {
        steps.push("Project build failed");
        progress = 0;
      }

      if (timelineBlock.buildStatus === TIMELINE_STATUS.IN_PROGRESS) {
        timelineBlock.buildStatus = TIMELINE_STATUS.IN_PROGRESS;
      }

      return {
        ...timelineBlock,
        title: `Build ${timelineBlock.buildStatus}`,
        type: TIMELINE_TYPE.BUILD,
        status: timelineBlock.buildStatus,
        time: getRelativeTime(timelineBlock.createdAt),
        steps,
        progress,
      };
    }

    // project create timeline-block
    return {
      ...timelineBlock,
      title: "Project created",
      type: TIMELINE_TYPE.INFO_CREATED,
      status: TIMELINE_STATUS.SUCCESS,
      time: getRelativeTime(timelineBlock.createdAt),
    };
  });
};
