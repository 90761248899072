import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginFormComponent from "./LoginForm";
import { saveUserDataViaCookie } from "../../utils/auth-helper";
// import LoginImage from '../../assets/login_img_app.svg';

const LoginPage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const ssoVerifiedValue = queryParams.get("sso_verified");
  const isEmailVerified =
    queryParams.get("email_verified") === "true"
      ? true
      : queryParams.get("email_verified") === "false"
      ? false
      : null;
  const verifiedEmail = queryParams.get("email");

  console.log({ isEmailVerified });

  if (ssoVerifiedValue) {
    const isSaved = saveUserDataViaCookie();

    if (isSaved) {
      window.location.replace("/projects");
    }
  }

  return (
    <main className="login-page">
      <div className="backdrop">
        <LoginFormComponent
          isEmailVerified={isEmailVerified}
          verifiedEmail={verifiedEmail}
        />
      </div>
    </main>
  );
};

export default LoginPage;
