import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { redirect, useNavigate } from 'react-router-dom';
import { getRequest } from "../../api/global";
import Button from "../../components/Button";
import projectPlaceholderImage from "../../assets/project.svg";
import { getInitials } from "../../utils/text-helper";
import { getRelativeTime } from "../../utils/date-helper";
import Loader from "../../components/Loader";

const DashboardProjects = () => {
  const [projects, setProjects] = useState([]);
  const [shownProjects, setShownProjects] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useState(() => {
    (async () => {
      try {
        const { data } = await getRequest("project/all");
        setProjects(data.projects);
        setShownProjects(data.projects);
      } catch (e) {
        // alert("Something went wrong, Please reload.");
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const filterProjects = (searchValue) => {
    if (!searchValue) {
      setShownProjects(projects);
    }

    const regex = new RegExp(searchValue.split("").join(".*"), "i");
    const filteredProjects = projects.filter((project) =>
      regex.test(project.appName)
    );

    setShownProjects(filteredProjects);
  };

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(() => {
      filterProjects(searchValue);
      return searchValue;
    });
  };

  const onSearchClear = () => {
    setSearchText("");
    setShownProjects(projects);
  };

  if (isLoading) {
    return <Loader size="lg" />;
  }

  if (!projects?.length) {
    return (
      <div className="flex items-center flex-col gap-10 m-20">
        <h2 className="text-4xl font-extrabold text-gray-500 dark-to-replace:text-gray-400">
          Start Creating Your Projects
        </h2>

        <img
          className="mt-5"
          width={700}
          src={projectPlaceholderImage}
          alt="project"
        />
        <Button
          style={{ width: "fit-content" }}
          onClick={() => navigate("/create-project")}
        >
          Create new project
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="ml-2 w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className="sticky top-0 z-20 px-40 py-4 mb-5 flex items-center justify-between bg-white dark-to-replace:bg-gray-900 border-b">
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark-to-replace:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            onInput={onSearch}
            value={searchText}
            type="text"
            id="table-search-users"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark-to-replace:bg-gray-700 dark-to-replace:border-gray-600 dark-to-replace:placeholder-gray-400 dark-to-replace:text-white dark-to-replace:focus:ring-blue-500 dark-to-replace:focus:border-blue-500"
            placeholder="Search for projects"
          />
          {searchText && (
            <div
              onClick={onSearchClear}
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </div>
        <div>
          <Button onClick={() => navigate("/create-project")}>
            Create new project
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="ml-2 w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </Button>
        </div>
      </div>
      <div className="pl-40 pr-20 mh-4 mb-4">
        <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark-to-replace:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-indigo-100 dark-to-replace:bg-gray-700 dark-to-replace:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Project
                </th>
                <th scope="col" className="px-6 py-3">
                  Platform
                </th>
                <th scope="col" className="px-6 py-3">
                  Last Build At
                </th>
              </tr>
            </thead>
            <tbody>
              {shownProjects.map((project) => (
                <tr
                  key={project.id}
                  onClick={() => navigate(`/project/${project.id}`)}
                  className="bg-white border-b cursor-pointer dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700 hover:bg-blue-50 dark-to-replace:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark-to-replace:text-white"
                  >
                    {project?.logoImageDownloadLink ? (
                      <img
                        className="w-10 h-10 rounded-full border border-gray-200"
                        src={project?.logoImageDownloadLink}
                        alt="Logo"
                      />
                    ) : (
                      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark-to-replace:bg-gray-600">
                        <span className="font-medium text-gray-600 dark-to-replace:text-gray-300">
                          {getInitials(project.appName)}
                        </span>
                      </div>
                    )}
                    <div className="pl-3">
                      <div className=" font-semibold">{project.appName}</div>
                    </div>
                  </th>

                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark-to-replace:bg-blue-900 dark-to-replace:text-blue-300">
                        {project.platform}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {getRelativeTime(project.lastBuildTime) || "None"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DashboardProjects;
