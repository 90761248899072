import React from 'react'

import Activity from './Activity'

const DashboardHome = () => {
    return (
        <Activity />
    )
}

export default DashboardHome