import axios from "axios";

export const downloadFile = async (url) => {

    // Use regular expression to match the filename part
    const filenameMatch = url.match(/\/([^/?]+)(\?|$)/);
    const filePath = filenameMatch[1];
    const decodedFilename = decodeURIComponent(filePath);
    const fileName = decodedFilename.split('/').pop();

    const link = document.createElement('a');

    link.href = url;
    link.download = fileName;
    link.click();
}