import React from "react";
import {
  TIMELINE_TYPE,
  KEYS_DISPLAY_NAMES,
  SPLASH_IMAGE_TIMELINE_FIELD,
  LOGO_IMAGE_TIMELINE_FIELD,
  TIMELINE_STATUS,
} from "./timeline.constants";
import ProgressBar from "../../components/Progress/index";

import InfoupdateImage from "../../assets/info_update.svg";
import BuildProgressImage from "../../assets/build_progress.svg";
import BuildSuccessImage from "../../assets/build_success2.svg";
import BuildFailedImage from "../../assets/build_failed.svg";
import Button from "../../components/Button";
import { downloadFile } from "../../utils/download-helper";
import Tooltip from "../../components/Tooltip";

const BuildStepInfo = ({ screenData = null }) => {
  if (screenData?.type === TIMELINE_TYPE.BUILD) {
    const StepImage =
      screenData.status === TIMELINE_STATUS.SUCCESS
        ? BuildSuccessImage
        : screenData.status === TIMELINE_STATUS.FAILED
        ? BuildFailedImage
        : BuildProgressImage;
    console.log("datt:", screenData);
    return (
      <div className="w-full build-container bg-white build-container p-5 overflow-y-scroll h-screen">
        <div className="mt-5 mx-auto relative">
          <img className="mx-auto" width={350} src={StepImage} alt="project" />
          {screenData.buildLink && (
            <div className="max-w-fit absolute top-0 right-0">
              <Button
                type="secondary"
                onClick={() => {
                  downloadFile(screenData.buildLink);
                }}
              >
                Download Build
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="ml-2 w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </Button>
              <Tooltip
                tooltipText="Build ID"
                className="float-right mt-4 hint--bottom"
              >
                <span className="text-blue-800 text-sm">#{screenData.id}</span>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="mt-10 mx-10">
          {screenData.status === TIMELINE_STATUS.IN_PROGRESS && (
            <div className="text-gray-400 mb-4">
              This process may take up to 15-20 minutes. You can close this
              screen and check back later
            </div>
          )}
          <ProgressBar percent={screenData.progress} />
        </div>
        <ul className="mt-10 mx-10 font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark-to-replace:bg-gray-700 dark-to-replace:border-gray-600 dark-to-replace:text-white">
          {screenData?.steps &&
            screenData.steps.map((step, index) => (
              <li
                key={index}
                className="w-full text-sm px-4 py-2 border-b border-gray-200 dark-to-replace:border-gray-600"
              >
                {step}
              </li>
            ))}
        </ul>
      </div>
    );
  }

  if (screenData?.type === TIMELINE_TYPE.INFO_UPDATE) {
    return (
      <div className="w-full build-container bg-white build-container p-5 overflow-y-scroll h-screen">
        <img
          className="mt-5 mx-auto"
          width={250}
          src={InfoupdateImage}
          alt="project"
        />
        <ul className="w-full mt-10 font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark-to-replace:bg-gray-700 dark-to-replace:border-gray-600 dark-to-replace:text-white">
          {(screenData?.changes || []).map((change, index) => {
            return (
              <li
                key={index}
                className="w-full text-sm px-4 py-2 border-b border-gray-200 dark-to-replace:border-gray-600"
              >
                <span className="text-base capitalize">
                  {" "}
                  {KEYS_DISPLAY_NAMES[change.fieldName]}:
                </span>{" "}
                <br />
                {[
                  SPLASH_IMAGE_TIMELINE_FIELD,
                  LOGO_IMAGE_TIMELINE_FIELD,
                ].includes(change.fieldName) ? (
                  <>{KEYS_DISPLAY_NAMES[change.fieldName]} field was updated</>
                ) : (
                  <div>
                    {change.previous}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-10 inline-block mx-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                    {change.new}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return (
    <div className="w-full build-container bg-white build-container"></div>
  );
};

export default BuildStepInfo;
