import axios from "axios";
import {
  clearUserData,
  getAuthToken,
  updateRefreshToken,
} from "../utils/auth-helper";
import { toast } from "react-toastify";

const axiosClient = axios.create();

export const API_BASE_URL = `${process.env.REACT_APP_APIURL}/v1`;

axiosClient.defaults.baseURL = API_BASE_URL;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosClient.defaults.timeout = 30000;

axiosClient.defaults.withCredentials = false;

// Request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    //Dispatch any action on success
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      !originalRequest.url.includes("/auth/") &&
      error?.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await updateRefreshToken();

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        return axiosClient(originalRequest);
      } catch (refreshError) {
        clearUserData();
        window.location.replace("/login");
      }
    }

    console.log("some error", error?.response);
    // show server error message
    toast(error?.response?.data?.message || "Something went wrong", {
      type: "error",
    });

    return Promise.reject(error);
  }
);

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response.data);
}

export function postRequest(URL, payload, extraParams) {
  return axiosClient
    .post(`/${URL}`, payload, extraParams)
    .then((response) => response.data);
}

export function patchRequest(URL, payload, extraParams) {
  return axiosClient
    .patch(`/${URL}`, payload, extraParams)
    .then((response) => response.data);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response.data);
}

export default axiosClient;
