export function getRelativeTime(timestamp) {
  if (!timestamp) {
    return null;
  }

  const currentDate = new Date();
  const targetDate = new Date(timestamp);

  // Time difference in milliseconds
  const timeDiff = currentDate.getTime() - targetDate.getTime();

  // Time difference in days
  const daysDiff = Math.floor(timeDiff / (24 * 3600 * 1000));

  // Function to format time as hh:mm
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Function to get day of the week
  //   const getDayOfWeek = (date) => {
  //     const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //     return days[date.getDay()];
  //   };

  if (daysDiff === 0) {
    return `Today, ${formatTime(targetDate)}`;
  } else if (daysDiff === 1) {
    return `Yesterday, ${formatTime(targetDate)}`;
  } else {
    const month = targetDate.toLocaleString("default", { month: "long" });
    const day = targetDate.getDate().toString().padStart(2, "0");
    return `${month} ${day}, ${formatTime(targetDate)}`;
  }
}
