const addClass = (classname, isActive) => {
    if (isActive) {
        return classname
    }
    return ''
}

const addClassElse = (isActive, classname, elseClassName = '') => {
    if (isActive) {
        return classname
    }
    return elseClassName
}

export {
    addClass,
    addClassElse
}