import "./App.css";
import DashboardHome from "./pages/DashboardHome";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DashboardProjects from "./pages/DashboardProjects";
import RightView from "./components/RightView";
import Sidebar from "./components/Sidebar";
import ProjectPage from "./pages/Project";
import InitialActivity from "./pages/DashboardHome/Activity/InitialActivity";
import { ModalContextProvider } from "./contexts/modal.context";
import LoginPage from "./pages/Login";
import { PrivateRoute } from "./utils/route-helper";
import { getAuthToken } from "./utils/auth-helper";
import PricingPage from "./pages/Pricing";
import Settings from "./pages/Settings";
import ForgotPasswordPage from "./pages/Login/ForgotPassword";
import ResetPasswordPage from "./pages/Login/ResetPassword";
import Account from "./pages/Account";

const NON_AUTH_PAGES = [
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = getAuthToken();

  useEffect(() => {
    if (
      !isAuthenticated &&
      (!NON_AUTH_PAGES.includes(location.pathname) || location.pathname === "/")
    ) {
      navigate("/login");
    } else if (
      isAuthenticated &&
      (NON_AUTH_PAGES.includes(location.pathname) || location.pathname === "/")
    ) {
      navigate("/projects");
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <ModalContextProvider>
      <div className="flex">
        {!NON_AUTH_PAGES.includes(location.pathname) && <Sidebar />}
        <RightView>
          <Routes>
            <Route path="/register" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
             
            <Route
              path="/pricing"
              element={
                <PrivateRoute component={<PricingPage />}></PrivateRoute>
              }
            />
            <Route
              path="/projects"
              element={
                <PrivateRoute component={<DashboardProjects />}></PrivateRoute>
              }
            />
            <Route
              path="/create-project"
              element={
                <PrivateRoute component={<DashboardHome />}></PrivateRoute>
              }
            />
            <Route
              path="/project/:id"
              element={
                <PrivateRoute component={<ProjectPage />}></PrivateRoute>
              }
            />
            <Route
              path="/project/:id/edit"
              element={
                <PrivateRoute
                  component={<InitialActivity isEditScreen />}
                ></PrivateRoute>
              }
            />
            <Route
              path="/usage-info"
              element={<PrivateRoute component={<Settings />}></PrivateRoute>}
            />
            <Route
              path="/account"
              element={<PrivateRoute component={<Account />}></PrivateRoute>}
            />
          </Routes>
        </RightView>
        <ToastContainer autoClose={5000} />
      </div>
    </ModalContextProvider>
  );
}

export default App;
