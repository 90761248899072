export const TIMELINE_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const TIMELINE_TYPE = {
  INFO_UPDATE: "INFO_UPDATE",
  BUILD: "BUILD",
  INFO_CREATED: "INFO_CREATED",
};

export const SPLASH_IMAGE_TIMELINE_FIELD = "splashImageName";
export const LOGO_IMAGE_TIMELINE_FIELD = "logoImageName";

export const KEYS_DISPLAY_NAMES = {
  websiteUrl: "Website",
  platform: "Platform",
  splashImageName: "Splash Image",
  logoImageName: "Logo Image",
  appName: "App Name",
};
