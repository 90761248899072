import { getRequest, postRequest } from "./global";

export const createOrder = (data) => {
  return postRequest(`payment/create-order`, data);
};

export const validateOrder = (data) => {
  return postRequest(`payment/validate-order`, data);
};

export const getLatestPayment = () => {
  return getRequest(`payment/latest-payment`);
};

export const getAllPayments = () => {
  return getRequest(`payment/all-payments`);
};

export const getUsageStats = (startDate, endDate) => {
  return getRequest(
    `payment/usage-stats?startDate=${startDate}&endDate=${endDate}`
  );
};
