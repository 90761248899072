import React from 'react';
import './index.css';

const ProgressBar = ({ percent = 0 }) => {
    if (percent >= 100) {
        return null;
    }

    return (
        <div className="relative w-full bg-gray-200 rounded">
            <div style={{ width: `${percent}%` }} className="absolute top-0 h-4 rounded shim-blue bg-blue-600"></div>
        </div>
    );
};

export default ProgressBar;
