import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import buildPlaceholderImage from "../../assets/build.svg";
import ProjectBuild from "./ProjectBuild";
import BuildStepInfo from "./BuildStepInfo";
import ProjectInfo from "./ProjectInfo";
import Loader from "../../components/Loader";

import { getProjectData, getProjectTimeline } from "../../api/project";
import { transformTimelineData } from "./timeline.utils";
import { buildApp, getBuildData } from "../../api/build";
import { TIMELINE_STATUS, TIMELINE_TYPE } from "./timeline.constants";

const ProjectPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [projectInfo, setProjectInfo] = useState({});
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [isBuildInProgress, setBuildInProgress] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleItemClick = (index) => {
    setActiveItemIndex(index);
  };

  const [timeline, setTimeline] = useState([]);

  // 1. init
  useEffect(() => {
    (async () => {
      try {
        await getTimelineData();
        await getProjectInfoData();
      } catch (e) {
        console.error("Error while initial load of project", e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // 2. init and when user changes current build number
  useEffect(() => {
    const currentTimelineItem = timeline?.[activeItemIndex];

    if (
      !timerId &&
      currentTimelineItem?.type === TIMELINE_TYPE.BUILD &&
      currentTimelineItem.buildStatus === TIMELINE_STATUS.IN_PROGRESS
    ) {
      updateBuildData(currentTimelineItem.id);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [activeItemIndex, timeline?.[activeItemIndex]?.id]);

  const getProjectInfoData = async () => {
    const response = await getProjectData(id);
    setProjectInfo(response.data?.projectData);
  };

  const getTimelineData = async () => {
    const response = await getProjectTimeline(id);
    const timelineData = transformTimelineData(response.data);
    setTimeline(timelineData);
    setActiveItemIndex(0);

    if ([TIMELINE_STATUS.IN_PROGRESS].includes(timelineData[0]?.status)) {
      setBuildInProgress(true);
    } else {
      setBuildInProgress(false);
    }
  };

  const cleanupTimer = () => {
    clearTimeout(timerId);
    setTimerId(null);
  };

  const updateBuildData = async (buildId) => {
    const { data: responseData = null } = await (getBuildData(buildId) || {});

    // cleanup if finished
    if (
      !responseData ||
      [TIMELINE_STATUS.SUCCESS, TIMELINE_STATUS.FAILED].includes(
        responseData.buildStatus
      )
    ) {
      cleanupTimer();
      setBuildInProgress(false);
    } else {
      const interval = setTimeout(() => {
        updateBuildData(buildId);
      }, 45000);

      setTimerId(interval);
    }

    const newBuildData = transformTimelineData([responseData])[0];
    const updatedTimeline = timeline.map((buildData) => {
      if (buildData.id === buildId) {
        return newBuildData;
      } else {
        return buildData;
      }
    });

    setTimeline(updatedTimeline);
  };

  const handleBuildStart = async () => {
    try {
      setBuildInProgress(true);
      const buildAppResponse = await buildApp({ projectId: id });
      if (buildAppResponse) {
        // to reset the index so setinterval can start
        setActiveItemIndex(null);
        getTimelineData();
      }
    } catch (error) {
      console.error(error);
      setBuildInProgress(false);
    }
  };

  if (isLoading) {
    return <Loader size="lg" />;
  }

  return (
    <>
      {/* <div className="sticky h-16 top-0 px-20 py-4 mb-5 flex items-center justify-between bg-white dark-to-replace:bg-gray-900 border-b">
                <div className='pl-5'>
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark-to-replace:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="table-search-users" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark-to-replace:bg-gray-700 dark-to-replace:border-gray-600 dark-to-replace:placeholder-gray-400 dark-to-replace:text-white dark-to-replace:focus:ring-blue-500 dark-to-replace:focus:border-blue-500" placeholder="Search builds" />
                    </div>
                </div>
            </div> */}
      <div className="px-40 flex flex-col gap-4 mt-4">
        <ProjectInfo
          isBuildInProgress={isBuildInProgress}
          isNewProject={!timeline.length}
          projectData={projectInfo}
          handleBuildStart={handleBuildStart}
        />
        {!timeline?.length ? (
          <div className="flex items-center flex-col gap-10 m-10">
            <h2 className="text-4xl font-extrabold text-gray-500 dark-to-replace:text-gray-400">
              Start a new build
            </h2>

            <img
              className="mt-3"
              width={500}
              src={buildPlaceholderImage}
              alt="project"
            />
            <Button
              style={{ width: "fit-content" }}
              disabled={isBuildInProgress}
              onClick={handleBuildStart}
            >
              Start build
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="ml-2 w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            </Button>
          </div>
        ) : (
          <div className="flex">
            <ProjectBuild
              timelineData={timeline}
              handleItemClick={handleItemClick}
              activeItemIndex={activeItemIndex}
            />
            <BuildStepInfo screenData={timeline?.[activeItemIndex]} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectPage;
