import { getRequest, postRequest } from "./global";

/* For Build process related apis */

export const buildApp = (payload) => {
    return postRequest('project/build', payload);
}

export const getBuildData = (id) => {
    return getRequest(`project/build-data?id=${id}`,);
}

export const getBuildFile = ({ projectId, buildId, platform }) => {
    return getRequest(`project/build-file?projectId=${projectId}&buildId=${buildId}&platform=${platform}`,);
}