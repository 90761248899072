import { API_BASE_URL, getRequest, postRequest } from "./global";

/* For Build process related apis */

export const registerUser = (payload) => {
  return postRequest("auth/register", payload);
};

export const loginUser = (payload) => {
  return postRequest("auth/login", payload);
};

export const logoutUser = (payload) => {
  return postRequest("auth/logout", payload);
};

export const forgotPassword = (payload) => {
  return postRequest("auth/forgot-password", payload);
};

export const resetPassword = (token, payload) => {
  if (!token) {
    throw new Error('Token is not present, please try again')
  }
  
  return postRequest("auth/reset-password?token=" + token, payload);
};

export const googleLoginUser = () => {
  window.location.href = API_BASE_URL + "/auth/google-login";
};
