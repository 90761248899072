import React from "react";
import { addClass, addClassElse } from "../../utils/css-helper";
import "../../index.css";
import { getRelativeTime } from "../../utils/date-helper";
import Tooltip from "../Tooltip/index";
import { TIMELINE_STATUS } from "../../pages/Project/timeline.constants";

const Timeline = ({ data, activeItemIndex, handleItemClick }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <ol className="relative border-l border-gray-200 dark-to-replace:border-gray-700">
      {data.map((timelineItem, index) => (
        <li key={index} className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark-to-replace:border-gray-900 dark-to-replace:bg-gray-700"></div>
          <Tooltip>
            <time className="text-sm font-normal leading-none text-gray-400 dark-to-replace:text-gray-500">
              {timelineItem.time}
            </time>
          </Tooltip>
          <div
            onClick={() => handleItemClick(index)}
            className={`transform transition duration-500 mt-2 cursor-pointer border-gray-200 border block p-4 hover:bg-white ${addClassElse(
              activeItemIndex === index,
              "bg-white border-transparent -mr-5 -ml-10 rounded-l-lg border-l-8 border-l-blue-600",
              "rounded-lg hover:shadow-md hover:border-transparent"
            )}`}
          >
            <h3 className="text-lg font-semibold text-gray-900 dark-to-replace:text-white uppercase">
              {timelineItem.type === "BUILD" && (
                <span
                  className={`inline-flex mr-2 w-3 h-3 rounded-full ${addClass(
                    "bg-green-500",
                    timelineItem.status === TIMELINE_STATUS.SUCCESS
                  )} ${addClass(
                    "bg-yellow-300",
                    [
                      TIMELINE_STATUS.IN_PROGRESS,
                      TIMELINE_STATUS.IN_PROGRESS,
                    ].includes(timelineItem.status)
                  )} ${addClass(
                    "bg-red-500",
                    timelineItem.status === TIMELINE_STATUS.FAILED
                  )}`}
                ></span>
              )}
              {timelineItem.title}
            </h3>
            {/* <span className="mr-3 mb-4 text-base font-normal text-gray-500 dark-to-replace:text-gray-400">{timelineItem.platform}</span> */}
            <div>
              {timelineItem.platform && (
                <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark-to-replace:bg-blue-900 dark-to-replace:text-blue-300">
                  {timelineItem.platform}
                </span>
              )}
              {timelineItem.id && (
                <Tooltip
                  tooltipText="Build ID"
                  className="float-right hint--bottom"
                >
                  <span className="text-blue-800 text-xs">
                    #{timelineItem.id?.slice(0, 6)}
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
};

export default Timeline;
