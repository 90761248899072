import axios from "axios";
import { loginUser, logoutUser } from "../api/auth";
import { API_BASE_URL } from "../api/global";

export const authenticateUser = (credentials) => {
  return loginUser({
    email: credentials.email,
    password: credentials.password,
  }).then((userData) => {
    saveUserData(userData);

    return {
      status: true,
    };
  });
};

export const saveUserData = (userData) => {
  if (userData?.tokens) {
    localStorage.setItem("tokens", JSON.stringify(userData.tokens));
  }

  if (userData?.user) {
    localStorage.setItem("user", JSON.stringify(userData.user));
  }

  return;
};

export const clearUserData = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("tokens");
};

export const getAuthToken = () => {
  const tokens = localStorage.getItem("tokens");
  const { access } = JSON.parse(tokens || "{}");

  return access?.token;
};

export const getRefreshToken = () => {
  const tokens = localStorage.getItem("tokens");
  const { refresh } = JSON.parse(tokens || "{}");

  return refresh?.token;
};

export const updateRefreshToken = async () => {
  try {
    const response = await axios.post(API_BASE_URL + "/auth/refresh-tokens", {
      refreshToken: getRefreshToken(),
    });

    // Update the stored access token with the new one
    const tokens = response.data;
    localStorage.setItem("tokens", JSON.stringify(tokens));

    // return access token
    return tokens?.access.token;
  } catch (error) {
    throw error;
  }
};

export const onLogoutUser = () => {
  const refreshToken = getRefreshToken();

  return logoutUser({ refreshToken })
    .then(() => {
      clearUserData();
      return true;
    })
    .catch(() => false);
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const clearCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const saveUserDataViaCookie = () => {
  const userData = getCookie("sso_auth");

  if (!userData) {
    return false;
  }
  debugger;
  saveUserData(JSON.parse(userData));
  clearCookie("sso_auth");
  return true;
};

export function isValidEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export const isValidPassword = (password) => {
  if (password.length < 7) {
    return false;
  }

  const hasNumber = /\d/.test(password);
  const hasAlphabet = /[a-zA-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return hasNumber && hasAlphabet && hasSpecialChar;
};
