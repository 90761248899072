import { useState } from 'react';

export const AWAITABLE_COMPONENT_STATUS = {
    IDLE: 'idle',
    AWAITING: 'awaiting',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
};

function useAwaitableComponent() {
    const [data, setData] = useState({
        status: AWAITABLE_COMPONENT_STATUS.IDLE,
        resolve: null,
        reject: null,
    });

    const handleResolve = (val) => {
        if (data.status !== AWAITABLE_COMPONENT_STATUS.AWAITING) {
            throw new Error('Awaitable component is not awaiting.');
        }
        data.resolve(val);
        setData({
            status: AWAITABLE_COMPONENT_STATUS.RESOLVED,
            resolve: null,
            reject: null,
        });
    };

    const handleReject = (err) => {
        if (data.status !== AWAITABLE_COMPONENT_STATUS.AWAITING) {
            throw new Error('Awaitable component is not awaiting.');
        }
        data.reject(err);
        setData({
            status: AWAITABLE_COMPONENT_STATUS.REJECTED,
            resolve: null,
            reject: null,
        });
    };

    const handleReset = () => {
        setData({
            status: AWAITABLE_COMPONENT_STATUS.IDLE,
            resolve: null,
            reject: null,
        });
    };

    const handleExecute = () => {
        return new Promise((resolve, reject) => {
            setData({
                status: AWAITABLE_COMPONENT_STATUS.AWAITING,
                resolve,
                reject,
            });
        });
    };

    return [data.status, handleExecute, handleResolve, handleReject, handleReset];
}

export default useAwaitableComponent;
