import React from "react";

const sizeConfig = {
  sm: "w-4 h-4",
  md: "w-8 h-8",
  lg: "w-32 h-32",
};

export default function Loader({ size = "md" }) {
  const cssConfig = sizeConfig[size];

  return (
    <div role="status" className="h-full">
      <div className="flex h-full justify-center items-center">
        <div
          className={`rounded-full ${cssConfig} bg-blue-600 animate-ping`}
        ></div>
      </div>
    </div>
  );
}
