import React from 'react'

const Input = (props) => {
    return (
        <div className="relative ">
            <input type="text" id="rounded-email" className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" {...props} />
        </div>
    )
}

export default Input